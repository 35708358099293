<template>
  <section class="career-section">
    <div class="career-section__header">
      <BreadCrumb class="top-left" />

      <div class="career-section__header__banner do-flex">
        <div class="career-section__header__banner__text">
          <div class="career-section__header__banner__text__title">
            Lowongan Karir
          </div>
        </div>
        <ImageLazy
          class="career-section__header__banner__image"
          :src="imgSrcBanner"
          alt="Karir Lion Parcel"
          @error="(event) => setImageDefault(event, 'career')"
        />
      </div>
    </div>
    <CareerFilter @filter="getCareers" />
    <div
      ref="career-list-element"
      class="career-section__list"
    >
      <div
        v-if="!isLoading && careers.length"
        class="career-section__list__count-container"
      >
        <div class="career-section__list__count-container__text">
          Menampilkan <span class="count">{{ careers.length }}</span> Lowongan
        </div>
      </div>
      <div
        v-if="!isLoading && careers.length"
        class="career-section__list__container"
      >
        <CareerCard
          v-for="(career, i) in paginatedData"
          :key="i"
          :position="career.job_position"
          :status="career.employment_status"
          :division="career.division_name"
          :location="career.city"
          @click="() => gotoDetailPage(career)"
        />
      </div>
      <CareerNotFound v-if="!isLoading && !careers.length" />
      <div
        v-if="isLoading"
        class="loader-container"
      >
        <Loader color-class="l-grey" />
      </div>
    </div>
    <div class="career-section__pagination">
      <div class="career-section__pagination__container">
        <Pagination
          v-if="!isLoading && careers.length"
          v-model="page"
          :per-page="10"
          :total-all-item="careers.length"
          @next="nextPage"
          @prev="prevPage"
        />
      </div>
    </div>
  </section>
</template>
<script>
import BreadCrumb from "@/components/new-breadcrumb/BreadCrumb";
import CareerFilter from "@/pages/new-career/components/CareerFilter.vue";
import CareerCard from "@/pages/new-career/components/CareerCard.vue";
import Pagination from "@/components/new-pagination/Pagination.vue";
import Loader from "@/components/new-loader/Loader.vue";
import CareerNotFound from "@/pages/new-career/components/CareerNotFound.vue";
import CareerUsecase from "@/pages/new-career/app/usecase/career";
import ImageLazy from "@/components/ImageLazy/index.vue";
import { mapGetters } from "vuex";
import { setImageDefault } from "@/components/ImageLazy/util";
import { checkFilterDropdownValue } from "@/pages/new-career/app/helpers/career-filter";
import mixinMobile from "@/misc/mixinMobile";
import mixinMetaInfo from "@/misc/mixinMetaInfo";

export default {
  components: {
    BreadCrumb,
    CareerFilter,
    CareerCard,
    Pagination,
    CareerNotFound,
    Loader,
    ImageLazy,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: "channel",
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      careers: [],
      pagination: {},
      isLoading: true,
      setImageDefault,
      searchMetaInfo: "_career_jobs",
      page: 1,
    };
  },
  computed: {
    ...mapGetters(["FILTER"]),
    imgSrcBanner() {
      return this.windowWidth <= 599
        ? '/images/career/banner_career_job_mobile.webp'
        : '/images/career/banner_career_job.webp';
    },
    paginatedData() {
      return (
        this.careers.length &&
        this.careers.slice((this.page - 1) * 10, this.page * 10)
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCareers();
  },
  methods: {
    async getCareers() {
      const query = `division_name=${checkFilterDropdownValue(
        this.FILTER.division
      )}&city=${checkFilterDropdownValue(this.FILTER.city)}&q=${
        this.FILTER.search
      }`;

      this.page = 1;
      this.careers = [];
      this.isLoading = true;

      const res = await CareerUsecase.getAll(query);
      this.careers = res.data.data;
      if (checkFilterDropdownValue(this.FILTER.type)) {
        this.careers = this.careers.filter((career) => {
          return (
            career.employment_status ===
            checkFilterDropdownValue(this.FILTER.type)
          );
        });
      }
      this.pagination = res.data.pagination;
      this.isLoading = false;
      this.scrollToTopCareerList();
    },
    gotoDetailPage(career) {
      this.$router.push(
        `/career/detail/${career.career_id}/${encodeURIComponent(
          career.job_position
        )}`
      );
    },
    nextPage() {
      this.page++;
      this.scrollToTopCareerList();
    },
    prevPage() {
      if (this.page === 1) return;
      this.page--;
      this.scrollToTopCareerList();
    },
    scrollToTopCareerList() {
      let headerOffset = 40;
      let elementPosition = this.$refs[
        "career-list-element"
      ].getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
