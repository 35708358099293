<template>
  <section
    class="career-card"
    @click="onClick"
  >
    <div class="career-card__top">
      <div
        class="career-card__top__status"
        :style="
          `background: ${decideLabelStatus(status).color}; color: ${
            decideLabelStatus(status).fontColor
          }`
        "
      >
        {{ decideLabelStatus(status).label }}
      </div>
      <div class="career-card__top__info">
        <span>
          <span>{{ position }}</span>
        </span>
        <div class="career-card__top__info__position">
          <div class="career-card__top__info__position__text">
            <ImageLazy
              class="career-card__top__info__position__text"
              width="16px"
              height="17px"
              :src="'/images/ics_f_group_two.svg'"
              alt="group icon"
            />
            {{ division }}
          </div>
          <ImageLazy
            width="6px"
            height="7px"
            :src="'/images/eclipse-divider.svg'"
            alt="eclipse divider"
          />
          <div class="career-card__top__info__position__text">
            <ImageLazy
              class="career-card__top__info__position__text"
              width="16px"
              height="17px"
              :src="'/images/ics_f_location_pin.svg'"
              alt="location icon"
            />
            <span>{{ location }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="career-card__divider" />
    <div
      v-if="!isUseButton"
      class="career-card__button"
    >
      Lihat Detail
    </div>
    <slot />
  </section>
</template>
<script>
import ImageLazy from "@/components/ImageLazy/index.vue";
import decideLabelStatus from "../app/helpers/career-label";

export default {
  components: { ImageLazy },
  props: {
    position: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    division: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    isUseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      decideLabelStatus
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";
.career-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  width: 468px;
  height: 205px;
  background: $color-white;
  border: 1px solid $color-pensive-6;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;

  @include for-size(mobile) {
    width: 100%;
    height: unset;
  }

  &:hover {
    box-shadow: 0px 8px 15px rgba(26, 20, 33, 0.15);
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;

    &__status {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 8px;
      gap: 10px;
      height: 21px;
      border-radius: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;
      margin-bottom: 12px;

      & > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: $color-base-text;
      }

      &__position {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $color-base-text;

        &__text {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    height: 0px;
    border: 1px solid $color-gray-pensive-4;
  }

  &__button {
    cursor: pointer;
    margin-top: 24px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: $color-base;
    justify-content: center;
  }
}
</style>
