<template>
  <section class="career-filter">
    <div class="career-filter__card">
      <div class="career-filter__card__division">
        <Dropdown
          :value="FILTER.division"
          placeholder="Pilih Divisi"
          :options="DIVISIONS"
          :is-handle-long-text="true"
          @input="(val) => onChange(val, 'division')"
        />
      </div>
      <div class="career-filter__card__city">
        <Dropdown
          :value="FILTER.city"
          placeholder="Pilih Lokasi"
          :options="CITIES"
          :is-handle-long-text="true"
          @input="(val) => onChange(val, 'city')"
        />
      </div>
      <div class="career-filter__card__job-type">
        <Dropdown
          :value="FILTER.type"
          placeholder="Tipe Pekerjaan"
          :options="TYPES"
          @input="(val) => onChange(val, 'type')"
        />
      </div>
      <div class="career-filter__card__search">
        <Input
          tabindex="0"
          :value="FILTER.search"
          placeholder="Cari karir impian Anda..."
          name="search"
          @input="(val) => onChange(val, 'search')"
        >
          <template #icon>
            <img
              class="search-icon"
              :src="'/images/ics_o_search_shade.svg'"
              alt="search icon"
            >
          </template>
        </Input>
      </div>
      <Button
        tabindex="0"
        text="Cari"
        @click="onFilter"
      />
    </div>  
  </section>
</template>
<script>
import Dropdown from "@/components/new-dropdown/Dropdown.vue";
import Input from "@/components/new-input/Input.vue";
import Button from "@/components/new-button/Button.vue";
import {mapGetters, mapActions} from "vuex"

export default {
  components: {Dropdown, Input, Button},
  data() {
    return {
      filter: {
        division: [],
        city: [],
        type: [],
        search: ''
      }
    }
  },
  computed: {
    ...mapGetters(['FILTER', 'DIVISIONS', 'CITIES', 'TYPES']),
  },
  mounted() {
    this.filter = this.FILTER
    this.$store.dispatch('setLocation')
    this.$store.dispatch('setDivision')
  },
  methods: {
    ...mapActions(['setFilter']),
    onChange(value, key) {
      this.setFilter({
        key,
        value,
      })
    },
    onFilter() {
      this.$emit('filter', this.FILTER)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

@mixin full-width {
  @include for-size(mobile) {
    width: 100%;
  }

  @media (min-width: 600px) and (max-width: 955px) {
    width: 100%;
  }
}

.career-filter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;

  @include for-size(mobile) {
    margin-top: -25px;
  }

  @media (min-width: 600px) and (max-width: 955px) {
    background-color: $color-soft-pink;
  }

  &__card {
    margin-left: 24px;
    margin-right: 24px;
    padding: 24px;
    display: flex;
    max-width: 956px;
    gap: 12px;
    width: 100%;
    background: $color-white;
    box-shadow: 0px 4px 8px rgba(26, 20, 33, 0.15);
    border-radius: 8px;
    z-index: 5;

    @include for-size(mobile) {
      flex-direction: column;
    }

    @media (min-width: 600px) and (max-width: 955px) {
      flex-direction: column;
    }

    ::v-deep .wrapper-multiselect-options {
      width: 100%;
      position: absolute;

      input {
        &::placeholder {
          font-size: 14px;
          line-height: 21px;
          transform: translateY(-1.5px);
        }
      }

      &:last-child {
        div {
          ul {
            height: unset;
            max-height: 12rem;
            min-height: 8rem;
            padding-bottom: 10px;
          }
        }
      }

      .single-select {
        display: flex;
        align-items: center;
      }

      @include for-size(mobile) {
        width: 100%;
      }

      @media (min-width: 600px) and (max-width: 955px) {
        width: 100%;
      }
    }

    ::v-deep .input-full {
      height: 42px;
      position: relative;

      .input-breakpoint-style {
        border: 1px solid $color-gray-pensive-5;
        padding-left: 35px !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        &::placeholder {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $color-gray-shade;
        }
      }

      .search-icon {
        position: absolute;
        color: $color-gray-shade;
        left: 10px;
        top: 27%;
      }
    }

    &__division {
      position: relative;
      width: 235px;
      height: 40px;
      z-index: 5;
      @include full-width;
    }

    &__city {
      position: relative;
      width: 160px;
      height: 40px;
      z-index: 4;
      @include full-width;
    }

    &__job-type {
      position: relative;
      width: 163px;
      height: 40px;
      z-index: 3;
      @include full-width;
    }

    &__search {
      width: 224px;
      height: 40px;
      z-index: 2;

      @include for-size(mobile) {
        width: 100%;
        margin-bottom: 12px;
      }

      @media (min-width: 600px) and (max-width: 955px) {
        width: 100%;
      }
    }
  }

  ::v-deep .custom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
