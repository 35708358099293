<template>
  <div class="career-not-found">
    <LazyImage
      class="career-not-found__image"
      width="100%"
      height="100%"
      :src="'/images/ill_spot_miscellaneous.svg'"
      alt="career not found"
    />
    <span class="career-not-found__title">
      Posisi Belum Tersedia
    </span>
    <span class="career-not-found__subtitle">
      Sayang sekali, kami belum membuka lowongan untuk posisi yang Anda cari
    </span>
  </div>
</template>
<script>
import LazyImage from "@/components/ImageLazy/index.vue";

export default {
  components: {LazyImage},
}

</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.career-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;

  &__image {
    width: 224px;
    height: 190px;
  }

  @include for-size(mobile) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__title {
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $color-base-text;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $color-gray-shade;
  }
}
</style>
