const decideLabelStatus = (status) => {
  if (status === "Full Time") {
    return {
      label: "Full Time",
      color: "#E9E7FD",
      fontColor: "#4F46A4",
    };
  }
  if (status === "Part Time") {
    return {
      label: "Part Time",
      color: "#C0EBCF",
      fontColor: "#00875A",
    };
  } else {
    return {
      label: "Intern",
      color: "#EAFCFF",
      fontColor: "#47A1BC",
    };
  }
}

export default decideLabelStatus
